const headerToggle = document.getElementsByClassName('header__toggle');
const headerBottom = document.getElementsByClassName('header__bottom');

headerToggle[0].addEventListener('click', function () {
    headerBottom[0].classList.toggle('open');
})

function openSubNav () {
    if (window.innerWidth < 768) {
        let parentLink = headerBottom[0].getElementsByClassName('menu-item-has-children');

        for (i = 0; i < parentLink.length; i++) {
            parentLink[i].addEventListener('click', function (event) {

                if (this.classList.contains('open')) {
                    //open page
                } else {
                    this.classList.toggle('open');
                    event.preventDefault();
                }
            })
        }
    }
}

window.addEventListener('load', openSubNav)

window.addEventListener('resize', openSubNav)




